@import "variables";
@import "functions";

// Small devices (landscape phones, 576px and up)
@media (min-width: 1px) {
  body.toggle-overlay {
    height: 100%;
    overflow: hidden;
  }
  .logo {
    width: 80px;
    object-fit: contain;
  }
  .logo-tagline {
    color: #979899;
    font-size: .7rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    margin-top: 5px;
  }
  .navbar-toggler {
    padding: 0;
    margin: 0 0 0 15px;
    width: auto;
    height: auto;
    font-size: 1.5rem;
    border: none;
    border-radius: 0;
    outline: none;
  }
  .navbar-toggler-icon {
    color: #baa88d;
    padding: 0;
    margin: 0;
    text-align: center;
    width: 1.5em;
    height: auto;
    overflow: hidden;
    display: block;
    outline: none;
    border: none;
  }
  .navbar-toggler-icon span {
    margin: 5px 0 0 0;
    padding: 0;
  }
  .navbar-toggler-icon:hover {
    color: #515151;
  }

  .navbar-nav .nav-item {
    margin-left: .4em;
    margin-right: .4em;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 1.1em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #515151;
    transition: all 0.1s ease-in-out 0s;
    -webkit-transition: all 0.1s ease-in-out 0s;
  }

  .navbar-nav .nav-item.active .nav-link ,
  .navbar-nav .nav-item.current-menu-item .nav-link ,
  body.tax-product_cat .navbar-nav .nav-item:nth-child(3) .nav-link {
    color: $color-primary;
  }
  .navbar-nav .nav-item .nav-link:hover {
    color: $color-primary;
  }

  .navbar-nav .dropdown {
    margin-right: 3rem;
  }
  .navbar-nav .dropdown-menu {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    border-radius: 0;
  }

  .navbar-nav .dropdown-menu a {
    color: #b3b3b3;
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.7;
    border-bottom: 1px solid #c2c2c2;
    margin-left: 2px;
    margin-right: 2px;
  }

  .navbar-nav .dropdown-menu a:last-child {
    border-bottom: none;
  }
  .navbar-nav .dropdown-menu a:hover {
    color: $color-primary;
    background-color: rgba($bg-brown, 0.1);
  }

  .navbar-nav .dropdown-toggle::after {
    border: none;
    width: 14px;
    height: 8px;
    content: "";
    display: inline-block;
    top: 17px;
    margin-left: 7px;
    position: absolute;
    background: url("../images/icon_nav_dropdown.png") center center no-repeat;
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(#baa88d, 1);
    overflow-x: hidden;
    display: none;
    z-index: 0;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
  }
  .overlay.show {
    display: block;
    z-index: 90000;
  }
  .logo-overlay {
    margin-top: 10px;
    width: 110px;
    height: auto;
    object-fit: contain;
  }
  .logo-tagline-overlay {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    margin-top: 7px;
  }
  .navbar-toggler-close {
    width: 18px;
    height: 18px;
    padding: 0;
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
  }
  .navbar-toggler-close-icon {
    width: 18px;
    height: 18px;
    display: block;
    outline: none;
    background: url("../images/icon_close_overlay.png") 0 0 no-repeat;
  }
  .m-nav-list {
    list-style: none inside;
    margin: 10px auto 0;
    padding: 0;
    width: 100%;
  }
  .m-nav-list li {
    list-style: none;
    text-align: center;
    display: block;
    font-size: 20px;
    font-weight: 300;
    line-height: 60px;
    color: #ffffff;
    background-color: transparent;
    border-bottom: 1px solid rgba(#ffffff, 0.8);
  }
  .m-nav-list li a {
    color: #ffffff;
    background-color: transparent;
    display: block;
  }
  .m-nav-list li a:hover {
    color: #857967;
    background-color: rgba(#ffffff, 0.5);
  }
  .m-nav-list ul {
    margin: 0;
    padding: 0;
  }
  .m-nav-list ul li {
    list-style: none;
    text-align: center;
    display: block;
    font-size: 20px;
    font-weight: 300;
    line-height: 60px;
    color: #ffffff;
    background-color: transparent;
    border-bottom: none;
  }
  .m-nav-list .sub-menu li {
    font-size: 18px;
    line-height: 54px;
  }
  .list-footer-nav {
    margin-top: 20px;
  }
  .list-footer-nav li.list-follow-up {
    display: table;
    margin-top: 1rem;
  }
  .col-full-image {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
  .block-content {
    padding-left: 0;
    padding-right: 0;
  }
  .form-control.add-cart-qty {
    width: 80px;
  }
  .card-highlight h1 {
    display: none;
  }
  .content-highlight ul {
    margin-left: 23px;
    padding-left: 0;
  }
  .selling-point {
    margin-bottom: 30px;
  }
  .card-highlight {
    h1 {
      font-size: inherit;
    }
    p {
      font-size: inherit;
    }
    img {
      margin-bottom: 10px;
    }
  }
  .card-product {
    height: 100%;
    padding: 10px 5px;
  }
  .card-product .card-body {
    height: auto;
  }
  .card-product .product-name {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 0;
  }
  .card-product .product-desc {
    font-size: 1rem;
    font-weight: 300;
  }
  .card-product .product-price {
    font-size: 1rem;
  }
  .btn-cart-mobile {
    font-size: .8rem;
  }
  table.shop_table_responsive tr td {
    font-size: 16px;
  }
  table.cart .product-thumbnail img {
    max-width: 100%;
  }
  .carousel-control-prev ,
  .carousel-control-next {
    width: 20px;
    opacity: 1;
    background-color: #ffffff;
  }
  .carousel-control-prev {
    left: -5px;
  }
  .carousel-control-next {
    right: -5px;
  }
  .carousel-control-prev-icon {
    width: 20px;
    height: 39px;
    background: url("../images/btn_carousel_direction_m.png") 0 0 no-repeat;
  }
  .carousel-control-next-icon {
    width: 20px;
    height: 39px;
    background: url("../images/btn_carousel_direction_m.png") 100% 0 no-repeat;
  }
  .card-carousel-item {
    padding-left: 0;
    padding-right: 0;
  }
  .card-product .product-desc {
    margin-bottom: 0;
  }
  .card-carousel-item .card-body ,
  .card-carousel-item .card-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .card-carousel-item .card-footer {
    padding-top: 0;
    padding-bottom: 0;
  }
  .featured-title {
    margin-bottom: 20px;
  }
  .product-item {
    padding: 0 10px;
  }
  .woocommerce .quantity {
    height: 30px;
  }

  .woocommerce .quantity .qty {
    width: 35px !important;
    line-height: 27px;
  }
  .woocommerce .quantity .minus, .woocommerce .quantity .plus {
    font-size: 30px !important;
    font-weight: 300 !important;
    width: 40px !important;
    height: 30px !important;
  }

  form.cart {
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
  }

  button.single_add_to_cart_button {
    font-size: 18px !important;
    padding: 3px 10px !important;
    float: right;
  }

  .list-category-sidebar li ul.sub-menu > li {
    font-size: .8em;
  }

  .list-category-sidebar ul.sub-menu {
    display: none;
  }

  .list-category-sidebar > li:first-child > ul.sub-menu {
    display: block;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  body {
    color: $text-color;
    font-size: $font-size-base;
    font-weight: 300;
    font-family: $font-family-body;
    line-height: $line-height-base;
    background-color: $bg-body;
  }
  .nav-header {
    margin-bottom: 0;
  }
  .logo {
    width: 177px;
    height: auto;
    object-fit: contain;
    padding-top: 1.25rem;
    margin-bottom: 17px;
  }
  .logo-tagline {
    font-family: Prompt;
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #979899;
  }

  .list-footer-nav li.list-follow-up {
    display: inline-block;
    margin-top: inherit;
  }
  .col-full-image {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
  .block-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-nav .nav-item {
    margin-left: .8em;
    margin-right: .8em;
  }
  table.cart .product-thumbnail img {
    max-width: 3.706325903em;
  }
  td.product-quantity {
    width: 180px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .list-footer-nav {
    margin-top: inherit;
  }
  .list-footer-nav li {
    border-left: 2px solid #9c9c9c;
    padding-left: 15px;
  }
  .col-full-image {
    padding-left: inherit;
    padding-right: inherit;
    margin-bottom: inherit;
  }
  .block-content {
    padding-left: inherit;
    padding-right: inherit;
  }
  .home-sec1-content {
    padding-left: 40px;
  }
  .about-sec1-content {
    padding-left: 40px;
  }
  .about-sec2-content {
    padding-right: 40px;
  }
  .about-sec3-content {
    padding-right: 40px;
  }
  .home-sec3-content {
    padding-top: 50px;
    padding-left: 50px;
  }
  .home-sec4-content {
    padding-left: 30px;
    padding-top: 50px;
  }
  .series-sec1-content {
    padding-left: 40px;
  }
  .series-sec2-content {
    padding-left: 40px;
  }
  .series-sec2-image {
    padding-left: 40px;
  }
  .series-sec3-image-offset{
    padding-right: 40px;
  }
  .card-highlight {
    h1 {
      font-size: 50px;
    }
    img {
      margin-bottom: 50px;
    }
    .card-highlight h1 {
      display: block;
    }
  }
  .card-product {
    padding: 15px 10px;
  }
  .card-product .card-body {
    height: auto;
  }
  .card-product .product-name {
    line-height: normal;
    margin-bottom: 5px;
  }
  .card-product .product-desc {
    font-weight: 300;
  }
  .navbar-nav .nav-item {
    margin-left: 1em;
    margin-right: 1em;
  }
  .btn-lg {
    padding: 10px 20px;
  }
  .btn-md {
    padding: 5px 10px;
  }
  table.shop_table_responsive tr td {
    font-size: inherit;
  }
  .carousel-control-prev ,
  .carousel-control-next {
    width: 30px;
    opacity: 1;
    background-color: #ffffff;
  }
  .carousel-control-prev {
    left: -30px;
  }
  .carousel-control-next {
    right: -30px;
  }
  .carousel-control-prev-icon {
    width: 30px;
    height: 58px;
    background: url("../images/btn_carousel_direction.png") 0 0 no-repeat;
  }
  .carousel-control-next-icon {
    width: 30px;
    height: 58px;
    background: url("../images/btn_carousel_direction.png") 100% 0 no-repeat;
  }
  .card-carousel-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-item {
    padding: inherit;
  }
  .woocommerce .quantity {
    height: 44px;
  }

  .woocommerce .quantity .qty {
    width: 70px !important;
    line-height: 42px;
  }
  .woocommerce .quantity .minus, .woocommerce .quantity .plus {
    font-size: 38px !important;
    font-weight: 400 !important;
    width: 60px !important;
    height: 44px !important;
  }

  td.product-quantity {
    width: 230px;
  }
  form.cart {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
  }
  button.single_add_to_cart_button {
    font-size: 19px !important;
    padding: 5px 15px !important;
    float: left;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .home-sec1-content {
    padding-left: 40px;
  }
  .home-sec2-content {
    padding-right: 40px;
    padding-left: 0;
  }
  .about-sec1-content {
    padding-left: 40px;
  }
  .about-sec2-content {
    padding-right: 40px;
  }
  .about-sec3-content {
    padding-right: 40px;
  }
  .home-sec3-content {
    padding-top: 50px;
    padding-left: 50px;
  }
  .home-sec4-content {
    padding-left: 30px;
    padding-top: 50px;
  }
  .home-sec5-content {
    padding-right: 40px;
  }

  .card-product .card-body {
    height: auto;
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .home-sec1-content {
    padding-left: 40px;
    padding-right: 0;
  }
  .home-sec2-content {
    padding-right: 40px;
  }
  .home-sec3-content {
    padding-top: 50px;
    padding-left: 50px;
  }
  .home-sec4-content {
    padding-left: 30px;
    padding-top: 50px;
  }
  .home-sec5-content {
    padding-right: 120px;
  }
  .about-sec1-content {
    padding-left: 120px;
  }
  .about-sec2-content {
    padding-right: 120px;
  }
  .about-sec3-content {
    padding-right: 140px;
  }
  .series-sec1-content {
    padding-left: 80px;
  }
  .series-sec2-content {
    padding-left: 40px;
  }
  .series-sec2-image {
    padding-left: 80px;
  }
  .series-sec3-content {
    padding-left: 0;
  }
  .series-sec3-image-offset{
    padding-right: 0;
  }
  .about-sec1-content img,
  .about-sec2-content img ,
  .about-sec3-content img {
    margin-bottom: 30px;
  }
}